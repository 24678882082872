import React from 'react';
import Img from "gatsby-image/withIEPolyfill"

const BlogDetailImages = ({gallery}) => {
    return (
        gallery.map((image, i) => {
            return (
                <div key={`blog-detail-image-${i}`}>
                    <figure>
                        <a href={image.fluid.srcWebp}>
                            <Img
                                fluid={image.fluid}
                                alt={image.title}
                                imgStyle={{borderRadius: "4px"}}
                                className="gallery-image uk-border-rounded"
                                 />
                        </a>
                    </figure>
                </div>
            )}
        )
    )
}
export default BlogDetailImages;
