import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../Layout";
import AstroLink from "../lib/AstroLink";
import { ButtonArrowRight } from "../components/UI/Button";
import { layoutOptions } from "../components/Content/RichText";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import BackgroundImage from "gatsby-background-image";
import * as Markdown from 'react-markdown';
import BlogDetailImages from "../components/Blog/BlogDetailImages";
import moment from 'moment';
import 'prismjs-darcula-theme/darcula.css';
import eye from "../images/icons/Eye.png";

function shufflePosts() {
    return (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };
}

const FullscreenHeader = ({ date, source, sourceUrl, media, content }) => {

    return (
        <div className="about__header uk-cover-container uk-overflow-hidden">
            {!media?.childImageSharp ?
                <>
                    <video
                        style={{
                            height: "100%",
                            zIndex: "-1"
                        }}
                        className={`uk-cover`}
                        playsInline
                        autoPlay
                        loop
                        data-uk-cover
                        data-uk-video="automute: true; autoplay: inview"
                        src={media.publicURL} type="video/mp4">
                    </video>
                    <div className="uk-container uk-height-1-1 uk-position-relative uk-light">
                        <div className={`uk-flex uk-flex-middle uk-height-1-1`}>
                            <div className={`uk-container uk-flex-auto uk-text-left`} data-uk-parallax="y: 0,312; opacity:0;media: @s">
                                <Markdown
                                    source={content}
                                    escapeHtml={false}
                                />
                            </div>
                        </div>
                    </div>
                </>
                :
                <BackgroundImage
                    Tag="div"
                    fluid={media.childImageSharp.fluid}
                    preserveStackingContext={true}
                    className="about__header uk-overflow-hidden"
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: `center center`,
                        height: "100%",
                        width: "100%"
                    }}
                    data-uk-parallax="scale: 1,1.25;blur: 0, 4px,5px;media: @s">
                    <div style={{ backgroundColor: "rgba(29, 38, 54, 0.5)" }} className="uk-position-cover disable-select" />
                    <div className={`uk-height-1-1 uk-flex uk-flex-left uk-flex-bottom uk-position-relative`}>
                        <div className="uk-width-2-3@m uk-padding-large uk-padding-remove-vertical uk-margin-large-bottom uk-light">
                            <Markdown
                                source={content}
                                escapeHtml={false}
                            />
                            {source ?
                                <p className="uk-text-meta">Published on {moment(date).format('MMMM D, YYYY')} | By <a style={{ textDecoration: "underline" }} className="uk-link uk-link-text" href={sourceUrl} target="blank">{source}</a></p>
                                :
                                <p className="uk-text-small uk-light">Posted on {moment(date).format('MMM D, YYYY')}</p>
                            }
                        </div>
                    </div>
                </BackgroundImage>

            }
        </div>
    );
};

const BlogPostContent = ({ data, location }) => {
    const d = data.contentfulBlog ? data.contentfulBlog : data.contentfulPress;
    const { slug, date, source, sourceUrl, title, topic, subtitle, mainImage, imageGallery, content } = d;

    const richText = documentToReactComponents(content.json, layoutOptions);
    const titleBlog = `${title}`;
    const description = subtitle ? subtitle.subtitle : titleBlog;
    const metaImage = `https://michaellisboa.com${mainImage.localFile.publicURL}`
    const metaUrl = `https://michaellisboa.com/blog/${slug}/`
    const allBlogs = data.allContentfulBlog.nodes.filter((post) => post.slug !== location.pathname.split("/")[2] && post.topic !== topic);
    const topicBlogs = data.allContentfulBlog.nodes.filter((post) => post.slug !== location.pathname.split("/")[2] && post.topic === topic);

    const [shuffledArray, setShuffledArray] = useState([]);
    const shuffle = shufflePosts();

    useEffect(() => {
        setShuffledArray(shuffle(allBlogs));
    }, []);

    return (
        <Layout location={location}>
            <Helmet>
                <title>{titleBlog}</title>
                <meta name="image" content={metaImage} />
                <meta name="url" content={metaUrl} />
                <meta name="description" content={`${description}`} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@Michael__Lisboa" />
                <meta name="twitter:title" content={titleBlog} />
                <meta name="twitter:image" content={metaImage} />
                <meta name="twitter:description" content={`${description}`} />

                <meta property="og:title" content={titleBlog} />
                <meta property="og:image" content={metaImage} />
                <meta property="og:description" content={`${description}`} />
                <meta property="og:url" content={metaUrl} />
                <meta property="og:site_name" content="Michael Lisboa" />
                <meta property="og:type" content="article" />
                <link rel="canonical" href={metaUrl} />
            </Helmet>
            <FullscreenHeader media={mainImage?.localFile} date={date} source={source} sourceUrl={sourceUrl} content={`<h1 class="h1__large">${title}</h1>${subtitle && subtitle.subtitle ? '<p class="uk-text-lead">' + description + '</p>' : ""}`} />
            {mainImage ?
                <section>
                    <div className="uk-container uk-container-expand">
                        <div className="uk-position-relative">
                            <Img
                                fluid={mainImage.fluid}
                                alt={mainImage.title}
                                objectFit="cover"
                                objectPosition="50% 50%" />
                        </div>
                    </div>
                </section>
                : null}
            <section className="uk-section uk-section-large">
                <div className="uk-grid uk-grid-collapse">
                    <div className="uk-width-expand">
                        {richText ?
                            richText :
                            <div className="uk-container">
                                <div className="uk-container uk-width-expand" dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }} />
                            </div>
                        }
                    </div>
                    <div className="uk-width-1-4@l uk-padding-small uk-padding-remove-vertical">
                        <div className="uk-margin-xlarge-top uk-hidden@l" />
                        <div className="uk-container uk-container-expand">
                            {topicBlogs.length > 1 ? <h5 className="uk-text-bold uk-margin-bottom">More {topic ? topic : "Blog"} Articles</h5> : null}
                            {topicBlogs.slice(0, 2).map((post, i) =>
                                <div key={i} className="uk-width-1-1 uk-card uk-margin-medium-bottom">
                                    <div className="uk-height-small uk-transition-toggle uk-position-relative uk-border-rounded uk-overlow-hidden">
                                        <AstroLink to={`/blog/${post.slug}/`}>
                                            <Img
                                                fluid={post.mainImage.localFile.childImageSharp.fluid}
                                                alt={post.mainImage.description}
                                                objectFit="cover"
                                                objectPosition="50% 50%"
                                                style={{ height: "100%" }}
                                                srcStyle={{ height: "100%" }} />
                                        </AstroLink>
                                        <AstroLink to={`/blog/${post.slug}/`}
                                            className={`overlayBackground uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                                            <img
                                                src={eye}
                                                style={{ maxWidth: "72px" }} />
                                        </AstroLink>
                                    </div>
                                    <div style={{ paddingLeft: "8px", paddingRight: "8px" }} className="uk-card-body uk-padding-small">
                                        <AstroLink to={`/blog/${post.slug}/`} className="uk-link-text uk-link-reset">
                                            <p className="uk-text-bold uk-margin-remove-vertical">{post.title}</p>
                                        </AstroLink>
                                        <div>
                                            <ButtonArrowRight
                                                url={`/blog/${post.slug}/`}
                                                label="View This Article"
                                                style={{ textDecoration: "none" }}
                                                css={`uk-margin-small-top cta-button uk-button-small uk-text-astro-darker`}
                                                color={`rgba(44, 44, 43, 1)`} />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <h5 className="uk-text-bold">Other Articles</h5>
                            {shuffledArray.slice(0, 3).map((post, i) =>
                                <div className="uk-margin-bottom">
                                    <div key={i} style={{ height: "155px" }} className="uk-grid uk-grid-collapse uk-grid-match">
                                        <div className="uk-height-1-1 uk-width-expand uk-transition-toggle uk-position-relative uk-overflow-hidden">
                                            <Img
                                                fluid={post.mainImage.localFile.childImageSharp.fluid}
                                                alt={post.mainImage.description}
                                                objectFit="cover"
                                                objectPosition="50% 50%"
                                                style={{ height: "100%" }}
                                                srcStyle={{ height: "100%" }} />
                                            <AstroLink to={`/blog/${post.slug}/`}
                                                className={`overlayBackground uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                                                <img
                                                    src={eye}
                                                    style={{ maxWidth: "56px" }} />
                                            </AstroLink>
                                        </div>
                                        <div style={{ height: "100%" }} className="uk-width-1-2 uk-width-3-5@xl uk-flex uk-flex-middle">
                                            <div className="uk-margin-small-left">
                                                <AstroLink to={`/blog/${post.slug}/`} style={{ textDecoration: "none" }} className="uk-link-text">
                                                    <p style={{fontSize: "0.85rem"}} className="uk-text-bold uk-margin-remove-vertical">{post.title}</p>
                                                </AstroLink>
                                                <ButtonArrowRight
                                                    url={`/blog/${post.slug}/`}
                                                    label="View This"
                                                    style={{ textDecoration: "none", fontSize: "0.65em" }}
                                                    css={`uk-margin-small-top cta-button uk-text-astro-darker`}
                                                    color={`rgba(44, 44, 43, 1)`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {imageGallery && imageGallery.length > 0 &&
                    <>
                        <div className="uk-container uk-visible@s">
                            <div className="uk-grid uk-grid-large uk-child-width-1-2 uk-child-width-1-3@m"
                                data-uk-grid
                                data-uk-lightbox
                                data-uk-height-match="target: .gallery-image">
                                <BlogDetailImages gallery={imageGallery} />
                            </div>
                        </div>

                        <div
                            className="uk-container uk-margin-medium uk-hidden@s"
                            data-uk-slider="autoplay: true; pause-on-hover: true; center: true">
                            <div className="uk-slider-items uk-grid uk-grid-large uk-child-width-1-2"
                                data-uk-grid
                                data-uk-lightbox
                                data-uk-height-match="target: .gallery-image">
                                <BlogDetailImages gallery={imageGallery} />
                            </div>
                            <div className="uk-margin-large uk-flex uk-flex-center">
                                <ul className="uk-slider-nav uk-dotnav" />
                            </div>
                        </div>
                    </>
                }
            </section>
        </Layout >
    );
};

export default BlogPostContent;
export const blogPostQuery = graphql`
    query blogPostQuery($slug: String) {
        contentfulBlog(slug: { eq: $slug }) {
            date
            content {
                json
            }
            imageGallery {
                fluid(maxWidth: 1000) {
                    ...GatsbyContentfulFluid_withWebp
                }
                description
            }
            mainImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            slug
            subtitle {
                subtitle
            }
            title
            topic
        }
        contentfulPress(slug: {eq: $slug}) {
            date
            title
            slug
            source
            sourceUrl
            mainImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2400) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                  publicURL
                }
              }
            content {
              childMarkdownRemark {
                html
                timeToRead
              }
            }
          }

          allContentfulBlog {
            nodes {
              date
              title
              subtitle {
                subtitle
              }
              topic
              mainImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                description
              }
              tile {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                description
              }
              imageGallery {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              slug
            }
          }
          allContentfulPress {
            nodes {
              date
              title
              source
              sourceUrl
              slug
              mainImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
    }`


